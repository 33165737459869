@import '../../assets/css/_variables.scss';

.associationToolContainer {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	height: 100px;
	width: 300px;
	padding: 10px;
	border-radius: 8px;
	background: white;
	opacity: .95;
	box-shadow: 0px 0px 5px 1px $box-shadow-darker-color;
	z-index: 5;
}

.associationToolText {
	text-align: center;
	color: $quaternary-green;
	font-size: .9rem;
	font-weight: 500;
	line-height: 1.2;
}

.associationToolButtons {
	width: 40%;
	margin: 10px auto 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.associationConfirmButton, .associationCancelButton {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	padding: 0;
}

.associationConfirmButton, .associationConfirmButton:focus {
	border-color: $color-success;
	box-shadow: none !important;
}

.associationCancelButton, .associationCancelButton:focus {
	border-color: $color-error;
	box-shadow: none !important;
}

.associationConfirmButtonDisabled {
	composes: associationConfirmButton;
	border-color: $button-grey !important;
	opacity: .7;
	cursor: auto;
}

.associationConfirmButton:hover {
	border-color: $color-success;
	background-color: $color-success-light;
}

.associationCancelButton:hover {
	border-color: $color-error;
	background-color: $color-error-light;
}

.associationConfirmButtonDisabled:hover {
	border-color: $button-grey;
	background-color: white;
}

.associationConfirmIcon {
	height: 60%;
	fill: $color-success;
}

.associationConfirmButtonDisabled .associationConfirmIcon, .associationConfirmButtonDisabled:hover .associationConfirmIcon {
	fill: $button-grey;
}

.associationCancelIcon {
	height: 60%;
	fill: $color-error;
}

.toolsContainer {
	position: absolute;
	top: 80px;
	left: 12px;
	z-index: 10;
	pointer-events: none
}

.toolButton {
	display: block;
	height: 40px;
	width: 40px;
	margin-bottom: 20px;
	border-radius: 50%;
	border: none;
	box-shadow: 0px 0px 5px 1px $box-shadow-darker-color;
	padding: 0;
	pointer-events: auto;
}

.toolButton:not(.selectedToolButton):hover {
	background-color: $secondary-light-green;
}

.toolButton:focus {
	border: none !important;
	box-shadow: 0px 0px 5px 1px $box-shadow-color !important;
	background-color: $secondary-light-green;
}

.selectedToolButton {
	composes: toolButton;
	background-color: $primary-green;
}

.selectedToolButton :global(.fillColored) {
	fill: white;
}

.selectedToolButton path:global(.strokeColored) {
	stroke: white;
}

.pinIcon {
	height: 65%;
	width: 65%;
	margin: 5px 0 0 5px;
}

.routeIcon {
	height: 70%;
	width: 70%;
	margin: 5px 2px 0 0;
}

.newPinToolContainer {
	position: absolute;
	bottom: 30px;
	right: 12px;
	z-index: 5;
}

.newPinTool {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	border: none;
	box-shadow: 0px 0px 5px 1px $box-shadow-darker-color;
	padding: 0;
}

.newPinTool:not(.selectedNewPinTool):hover {
	background-color: $secondary-light-green;
}

.newPinTool:not(.selectedNewPinTool):focus {
	border: none !important;
	box-shadow: 0px 0px 5px 1px $box-shadow-color !important;
	background-color: $secondary-light-green;
}

.selectedNewPinTool {
	composes: newPinTool;
	background-color: $primary-green;
}

.selectedNewPinTool :global(.fillColored) {
	fill: white;
}

.selectedNewPinTool :global(.strokeColored) {
	stroke: white;
}

.newPinIcon {
	height: 65%;
	width: auto;
	margin-top: 2px;
}

.tooltip {
	position: absolute;
	white-space: nowrap;
	padding: 1px 7px;
	border-radius: 7px;
	font-size: .65rem;
	background-color: $primary-green;
	color: white;
	font-weight: 400;
	z-index: 15;
	visibility: hidden;
	opacity: 0;
	transition: all .3s ease-out;
}

.pinsTooltip {
	composes: tooltip;
	top: 45px;
	left: 0;
}

.routesTooltip {
	composes: tooltip;
	top: 105px;
	left: 0;
}

.newPinTooltip {
	composes: tooltip;
	top: 55px;
	right: 0;
}

@media (hover: hover) {
	.toolButton:hover + .tooltip {
		visibility: visible;
		opacity: 1;
		transition: all .3s ease-out .4s;
	}
	.newPinTool:hover + .tooltip {
		visibility: visible;
		opacity: 1;
		transition: all .3s ease-out .4s;
	}
}

@media (max-width: 500px) {
	.toolsContainer {
		left: 7px;
	}
	.newPinTool {
		bottom: 15px;
		right: 7px;
	}
}