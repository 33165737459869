@import '../../assets/css/_variables.scss';

.modal {
  position: absolute;
  width: 100%;
  top: 65px;
  right: 0;
  bottom: 0;
  left: 0;
	z-index: 11;
	visibility: hidden;
	opacity: 0;
	transition: all .2s ease-out;
}

.modalShown {
	composes: modal;
	visibility: visible;
	opacity: 1;
}

.modalBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modalDialog {
	position: relative;
	margin: 15px auto 0;
	max-height: calc(100% - 17px);
	max-width: calc(100% - 14px);
	width: 300px;
	border-radius: 8px;
	box-shadow: 0px 0px 10px 0px $box-shadow-darker-color;
	background: transparent;
	overflow: auto;
}

.introContent {
	overflow: hidden;
}

.textSection {
	position: relative;
	color: white;
	text-align: center;
	padding: 10px 20px 20px;
}

.textSection::before {
  position: absolute;
  top: -25%;
  left: 0;
  width: 100%;
  height: 125%;
	content: '';
	background-color: rgba($primary-green, .8);
	transform: skewY(-10deg);
	z-index: -1;
}

.logoContainer {
	height: 30px;
	padding: 2px 8px;
	border-radius: 15px;
	background-color: white;
	display: inline-flex;
	align-items: center;
}

.logoContainer span {
	color: $primary-green;
	font-size: 1.1rem;
	font-weight: 700;
	letter-spacing: 2px;
	margin-right: 5px;
}

.logoContainer img {
	height: 100%;
	margin-top: 2px;
}

.textList {
	margin-top: 15px;
	text-align: left;
}

.textList li {
	margin-bottom: 5px;
	font-weight: 500;
}

.iconsSection {
	position: relative;
	height: 70px;
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.iconsSection::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
	content: '';
	background-color: rgba($secondary-green, .8);
	transform: skewY(-10deg);
	z-index: -1;
}

.iconContainer {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background-color: white;
	box-shadow: 0px 0px 5px 1px $box-shadow-darker-color;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pinIconContainer {
	composes: iconContainer;
	margin-bottom: -14.5%;
}

.routeIconContainer {
	composes: iconContainer;
	height: 45px;
	width: 45px;
}

.starIconContainer {
	composes: iconContainer;
	height: 40px;
	width: 40px;
	margin-top: -15%;
}

.pinIcon {
	height: 65%;
	width: 65%;
	margin-left: 7px;
}

.routeIcon {
	height: 70%;
	width: 70%;
	margin: 0 3px 2px 0;
}

.starIcon {
	height: 70%;
	width: 70%;
	margin: 0 0 2px 2px;
	fill: $color-yellow;
}

.sponsorsSection {
	position: relative;
	padding: 10px 20px;
	overflow: visible;
}

.sponsorsSection::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 125%;
	content: '';
	background-color: rgba($tertiary-green, .8);
	transform: skewY(-10deg);
	z-index: -1;
}

.sponsorsHeader {
	color: white;
	transform: skewY(-10deg);
	font-size: 1.2rem;
	font-weight: 700;
	text-align: center;
}

.sponsorsContainer {
	position: relative;
	height: 35px;
	margin-top: 35px;
}

.sponsorContainer {
	position: absolute;
	display: inline-block;
	height: 30px;
	max-width: 50px;
	transition: all .2s ease-out;
}

.unseenLeft {
	composes: sponsorContainer;
	visibility: hidden;
	transform: translateX(-70px);
}

.seenLeft {
	composes: sponsorContainer;
	visibility: visible;
	transform: translateX(0);
}

.seenMedium {
	composes: sponsorContainer;
	visibility: visible;
	height: 60px;
	max-width: 100px;
	transform: translateX(80px);
}

.seenRight {
	composes: sponsorContainer;
	visibility: visible;
	transform: translateX(210px);
}

.unseenRight {
	composes: sponsorContainer;
	visibility: hidden;
	transform: translateX(280px);
}

.secondaryButton {
	display: block;
	height: 40px;
	margin: 25px auto 0;
	background-color: white;
	border: 1px solid $quaternary-green;
	color: $quaternary-green;
	font-size: 1rem;
	font-weight: 700;
	border-radius: 5px;
	overflow: hidden;
}

.secondaryButton:focus {
	color: $quaternary-green;
	border-color: $quaternary-green;
	box-shadow: none !important;
}

.secondaryButton:hover {
	background-color: $secondary-light-green;
	color: $quaternary-green;
	border-color: $quaternary-green;
}

/* Style Scrollbar (Webkit only and only for desktop) */
@media (min-width: 1024px) {
	.modalDialog::-webkit-scrollbar-track
	{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
		background-color: $primary-light-green;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	.modalDialog::-webkit-scrollbar
	{
		width: 10px;
		border-radius: 8px;
		background-color: $primary-light-green;
	}
	.modalDialog::-webkit-scrollbar-thumb
	{
		border-radius: 8px;
		border: 1px solid transparent;
		background-clip: padding-box;
		background-color: $tertiary-light-green;
	}
}