@import '../../assets/css/_variables.scss';

.container {
	width: 400px;
	max-width: 90%;
	margin: 50px auto 0;
	background-color: white;
	padding: 40px;
	border-radius: 7px;
	box-shadow: 0px 0px 10px $box-shadow-darker-color;
}

.logoContainer {
	height: 40px;
	margin-bottom: 20px;
	padding: 3px 8px;
	border-radius: 15px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

.logoContainer span {
	color: $primary-green;
	font-size: 1.3rem;
	font-weight: 700;
	letter-spacing: 2px;
	margin-right: 5px;
}

.logoContainer img {
	height: 100%;
}

.header {
	margin: 0 0 20px;
	text-align: center;
	font-size: 1.5rem;
	color: $primary-green;
	text-shadow: $green-text-shadow;
}

.notificationDisplay {
	display: flex;
	align-items: center;
	margin: 0 0 15px;
}

.errorIcon {
	height: 18px;
	width: 18px;
	min-height: 18px;
	min-width: 18px;
	margin-right: 10px;
	fill: $color-error;
}

.successIcon {
	composes: errorIcon;
	fill: $secondary-green;
}

.errorMessage {
	color: $color-error;
	font-size: .9rem;
	line-height: 1rem;
}

.successMessage {
	color: $secondary-green;
	font-size: .9rem;
	line-height: 1rem;
}

.field:not(:last-child) {
	margin-bottom: 7px;
}

.input {
	height: 40px;
	width: 100%;
	font: inherit;
	padding: 0 10px;
	background-color: white;
	border: 1px solid white;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
}

.input:hover {
	border-color: $tertiary-green;
}

.input:focus {
	outline: none;
	border-color: $secondary-green;
	box-shadow: 0 0 3px 3px $secondary-light-green;
}

.input::placeholder {
	color: $tertiary-light-green;
}

.submitButton {
	display: block;
	width: 100%;
	height: 40px;
	margin: 20px auto 0;
	background-color: $primary-green;
	color: white;
	font-size: 1.1rem;
	border: 0;
	border-radius: 5px;
}

.submitButton:focus {
	color: white;
	box-shadow: none !important;
}

.submitButton:hover {
	background-color: $primary-light-green;
	color: $primary-green;
	border: 1px solid $primary-green;
}