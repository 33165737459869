@import '../../assets/css/_variables.scss';

.modal {
  position: absolute;
  width: 100%;
  top: 65px;
  right: 0;
  bottom: 0;
  left: 0;
	z-index: 11;
	visibility: hidden;
	opacity: 0;
	transition: all .2s ease-out;
}

.modalShown {
	composes: modal;
	visibility: visible;
	opacity: 1;
}

.modalBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modalDialog {
	position: relative;
	margin: 15px auto 0;
	max-height: calc(100% - 17px);
	max-width: calc(100% - 14px);
	width: 500px;
	border-radius: 8px;
	box-shadow: 0px 0px 10px 0px $box-shadow-darker-color;
	background-color: white;
	padding: 15px 40px;
	overflow-y: auto;
}

.closeButton {
	position: absolute;
	top: 4px;
	right: 4px;
	background-color: white;
	border: 1px solid $primary-green;
}

.closeButton::before, .closeButton::after {
	background-color: $primary-green;
}

.closeButton:hover {
	background-color: white;
	border-color: $quaternary-green;
}

.closeButton:hover::before, .closeButton:hover::after {
	background-color: $quaternary-green;
}

.closeButton:focus {
	background-color: white;
}

@media (max-width: 500px) {
	.modalDialog {
		padding: 15px 15px;
	}
}

/* Style Scrollbar (Webkit only and only for desktop) */
@media (min-width: 1024px) {
	.modalDialog::-webkit-scrollbar-track
	{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
		background-color: transparent;
		border-radius: 8px;
	}
	.modalDialog::-webkit-scrollbar
	{
		width: 10px;
		border-radius: 8px;
		background-color: transparent;
	}
	.modalDialog::-webkit-scrollbar-thumb
	{
		border-radius: 8px;
		border: 1px solid transparent;
		background-clip: padding-box;
		background-color: $tertiary-light-green;
	}
}