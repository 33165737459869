@import '../../assets/css/_variables.scss';

html, body, :global(#root) {
  overflow-y: auto !important;
}

.pageContainer {
	margin: 30px 50px;
	text-align: center;
}

.logoContainer {
	height: 50px;
	padding: 2px 8px;
	border-radius: 15px;
	background-color: transparent;
	display: inline-flex;
	align-items: center;
}

.logoContainer span {
	color: $primary-green;
	font-size: 1.5rem;
	font-weight: 700;
	letter-spacing: 2px;
	margin-right: 5px;
}

.logoContainer img {
	height: 100%;
	margin-top: 2px;
}

.header {
	margin-top: 30px;
	font-size: 1.8rem;
	font-weight: 700;
}

.block {
	margin-top: 30px;
	text-align: left;
}

.subheader {
	font-size: 1.4rem;
	font-weight: 700;
	color: $primary-grey;
}

.block p {
	margin-top: 1rem;
	text-align: justify;
	line-height: 1.4rem;
}

.highlight {
	color: $route-light-blue;
	font-style: italic;
}

.bold {
	color: $quaternary-green;
	font-weight: 700;
}

.block ul {
	margin-left: 25px;
}

.table {
	margin-top: 1rem;
	border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

.table th {
	color: $route-light-blue;
}

.table th.tableHeader {
	text-align: center;
}

.table th.colName {
	width: 25%;
}

.table th.purpose {
	width: 25%;
}

.table td, th {
  border: 1px solid #dddddd;
  padding: 8px !important;
  // text-align: center !important;
}