@import '../../assets/css/_variables.scss';

.headerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 25px;
}

.header {
	font-size: 1.5rem;
	color: $primary-green;
	text-shadow: $green-text-shadow;
}

.headerIconContainer {
	height: 27px;
	width: 27px;
	border-radius: 50%;
	box-shadow: 0px 3px 6px $box-shadow-color;
	margin-right: 10px;
	margin-bottom: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pinIcon {
	height: 70%;
	width: auto;
	margin-left: 4px;
}

.categoriesList {
	width: fit-content;
	list-style: none;
	margin: 0 auto;
	padding: 0;
}

.categoryRow {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.checkboxContainer {
	position: relative;
	width: 17px;
  height: 17px;
  border-radius: 50%;
  margin: 0 12px 4px 0;
}

.checkedCheckboxContainer {
	composes: checkboxContainer;
  background-color: $secondary-green;
}

.checkbox {
	position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0px 3px 6px $box-shadow-color;
  z-index: 10;
  margin: 0;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}

.checkmark {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 70%;
	width: 70%;
	fill: white;
	opacity: 0;
	cursor: pointer;
}

.checkedCheckboxContainer .checkmark {
	opacity: 1;
}

.categoryText {
	margin-right: 10px;
}

.categoryIcon {
	height: 23px;
}

.buttonsContainer {
	display: flex;
	align-items: center;
}

.primaryButton {
	display: block;
	width: 45%;
	height: 40px;
	margin: 20px auto 0;
	background-color: $quaternary-green;
	color: $primary-green;
	font-size: 1rem;
	font-weight: 700;
	border: 0;
	border-radius: 5px;
	overflow: hidden;
}

.primaryButton:focus {
	color: $primary-green;
	box-shadow: none !important;
}

.primaryButton:hover {
	background-color: $quaternary-green-light;
	color: $primary-green;
	border: 1px solid $quaternary-green-light;
}

.secondaryButton {
	display: block;
	width: 45%;
	height: 40px;
	margin: 20px auto 0;
	background-color: white;
	border: 1px solid $quaternary-green;
	color: $quaternary-green;
	font-size: 1rem;
	font-weight: 700;
	border-radius: 5px;
	overflow: hidden;
}

.secondaryButton:focus {
	color: $quaternary-green;
	border-color: $quaternary-green;
	box-shadow: none !important;
}

.secondaryButton:hover {
	background-color: $secondary-light-green;
	color: $quaternary-green;
	border-color: $quaternary-green;
}

@media (max-width: 500px) {
	.primaryButton, .secondaryButton {
		font-size: .85rem;
	}
}