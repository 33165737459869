@import '../../assets/css/_variables.scss';

.headerContainer {
	margin: 0 0 25px;
}

.header {
	font-size: 1.5rem;
	color: $primary-green;
	text-align: center;
	text-shadow: $green-text-shadow;
}

.rule {
	position: relative;
}

.rule:not(:last-child) {
	margin-bottom: 15px;
}

.leafIcon {
	position: absolute;
	top: 0;
	left: 0;
	height: 22px;
}

.rule p {
	margin-left: 28px;
	font-size: .95rem;
	line-height: 1.2;
	text-align: justify;
}
