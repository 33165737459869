@import '../../assets/css/_variables.scss';

.panel {
	position: absolute;
	top: 80px;
	right: 0;
	display: block;
	visibility: hidden;
	opacity: 0;
	width: 400px;
	max-width: calc(100% - 7px);
	background-color: white;
	padding: 15px 40px;
	border-top-left-radius: 7px;
	border-bottom-left-radius: 7px;
	transform-origin: right center;
	transform: scaleX(.1);
	transition: all .2s ease-out;
	box-shadow: -2px 2px 6px $box-shadow-darker-color;
	z-index: 11;
}

.panelOpen {
	composes: panel;
	visibility: visible;
	opacity: 1;
	transform: scaleX(1);
}

.closeButton {
	position: absolute;
	top: 4px;
	right: 4px;
	background-color: white;
	border: 1px solid $primary-green;
}

.closeButton::before, .closeButton::after {
	background-color: $primary-green;
}

.closeButton:hover {
	background-color: white;
	border-color: $quaternary-green;
}

.closeButton:hover::before, .closeButton:hover::after {
	background-color: $quaternary-green;
}

.closeButton:focus {
	background-color: white;
}