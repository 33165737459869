@import '../../assets/css/_variables.scss';

.headerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 20px;
}

.header {
	font-size: 1.5rem;
	color: $primary-green;
	text-shadow: $green-text-shadow;
}

.headerIconContainer {
	height: 27px;
	width: 27px;
	border-radius: 50%;
	box-shadow: 0px 3px 6px $box-shadow-color;
	margin-right: 10px;
	margin-bottom: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pinIcon {
	height: 70%;
	width: auto;
	margin-left: 4px;
}

.errorDisplay {
	display: flex;
	align-items: center;
	margin: 0 0 15px;
}

.errorIcon {
	height: 18px;
	width: 18px;
	min-height: 18px;
	min-width: 18px;
	margin-right: 10px;
}

.errorMessage {
	color: $color-error;
	font-size: .9rem;
	line-height: 1rem;
}

.field:not(:last-child) {
	margin-bottom: 15px;
}

.input {
	height: 40px;
	width: 100%;
	font: inherit;
	padding: 0 10px;
	background-color: white;
	border: 1px solid white;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
}

.input:hover {
	border-color: $quaternary-green;
}

.input:focus {
	outline: none;
	border-color: $secondary-green;
	box-shadow: 0 0 5px 3px $secondary-light-green;
}

.input::placeholder {
	color: $tertiary-light-green;
	opacity: 1;
}

.select {
	height: 40px !important;
	width: 100%;
}

.categoryIconContainer {
	position: absolute;
	top: 20%;
	left: 10px;
	height: 60%;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.categoryIcon {
	max-height: 100%;
	max-width: 100%;
}

.select select {
	height: 100%;
	width: 100%;
	font: inherit;
	padding: 0 0 0 10px;
	background-color: white;
	border: 1px solid white;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
}

.select select.withIcon {
	padding-left: 43px;
}

.select select:hover {
	border-color: $quaternary-green;
}

.select select:focus {
	outline: none;
	border-color: $secondary-green;
	box-shadow: 0 0 5px 3px $secondary-light-green;
}

.select:not(.is-multiple):not(.is-loading)::after {
	border-color: $primary-green;
}

.select:not(.is-multiple):not(.is-loading):hover::after {
	border-color: $primary-green;
}

.select select:invalid {
	color: $tertiary-light-green !important;
}

/* FIREFOX */
// Remove extra text padding/indent in <select>
@-moz-document url-prefix() {
  select {
    text-indent: -2px;
  }
}
// Fix option inheriting color from select:invalid;
.select select:invalid option {
	color: $primary-text;
}

.imageFrame {
	composes: input;
	position: relative;
	width: 55%;
	height: 150px;
	margin: 0 auto;
	cursor: pointer;
}

.imageContainer {
	composes: input;
	display: inline-block;
	position: relative;
	height: 150px;
	width: auto;
	margin: 0 auto;
	padding: 5px;
	cursor: pointer;
}

.image {
	height: 100%;
	width: auto;
}

.imageIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 50px;
	width: auto;
	fill: $tertiary-light-green;
}

.imageText {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	color: $tertiary-light-green;
}

.textarea {
	composes: input;
	height: auto;
	padding: 5px 10px;
	line-height: 1.3;
}

.pinAssociationButton {
	composes: input;
	justify-content: flex-start;
}

.pinAssociationButton:focus {
	outline: none;
	color: $tertiary-light-green;
	border-color: $secondary-green;
	box-shadow: 0 0 5px 3px $secondary-light-green !important;
}

.pinAssociationButtonPlaceholder {
	composes: pinAssociationButton;
	color: $tertiary-light-green;
}

.pinAssociationButtonPlaceholder:hover {
	color: $tertiary-light-green;
}

.pinAssociationLabel {
	color: $tertiary-light-green;
	margin-right: 5px;
}

.primaryButton {
	display: block;
	width: 100%;
	height: 40px;
	margin: 20px auto 0;
	background-color: $quaternary-green;
	color: $primary-green;
	font-size: 1rem;
	font-weight: 700;
	border: 0;
	border-radius: 5px;
}

.primaryButton:focus {
	color: $primary-green;
	box-shadow: none !important;
}

.primaryButton:hover {
	background-color: $quaternary-green-light;
	color: $primary-green;
	border: 1px solid $quaternary-green-light;
}

.primaryButton:global(.is-loading)::after {
	border-left-color: white;
	border-bottom-color: white;
}

.deleteButton {
	display: block;
	width: 100%;
	height: 40px;
	margin: 20px auto 0;
	background-color: white;
	color: $color-error;
	font-size: 1rem;
	font-weight: 700;
	border: 1px solid $color-error;
	border-radius: 5px;
}

.deleteButton:focus {
	color: $color-error;
	border-color: $color-error;
	box-shadow: none !important;
}

.deleteButton:hover {
	background-color: $color-error-light;
	color: $color-error;
	border-color: $color-error;
}

.deleteButton:global(.is-loading)::after {
	border-left-color: white;
	border-bottom-color: white;
}

.buttonsContainer {
	display: flex;
	align-items: center;
}

.buttonsContainer button {
	width: 45%;
}

/* Style Textarea Scrollbar (Webkit only and only for desktop) */
@media (min-width: 1024px) {
	.textarea::-webkit-scrollbar-track
	{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
		background-color: transparent;
		border-radius: 5px;
	}
	.textarea::-webkit-scrollbar
	{
		width: 10px;
		border-radius: 5px;
		background-color: transparent;
	}
	.textarea::-webkit-scrollbar-thumb
	{
		border-radius: 5px;
		border: 1px solid transparent;
		background-clip: padding-box;
		background-color: $tertiary-light-green;
	}
}