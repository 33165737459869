@import '../../assets/css/_variables.scss';

.navbar {
	position: absolute;
	height: 65px;
	min-height: 0;
	width: 100%;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	box-shadow: 0px 7px 7px rgba(25, 5, 5, 0.3);
	z-index: 15;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.navbarStart {
	width: 100%;
	height: 100%;
	margin: 0 35px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.logoContainer {
	height: 45%;
	padding: 2px 8px;
	border-radius: 15px;
	background-color: white;
	display: flex;
	align-items: center;
}

.logoContainer span {
	color: $primary-green;
	font-size: 1.1rem;
	font-weight: 700;
	letter-spacing: 2px;
	margin-right: 5px;
}

.logoContainer img {
	height: 100%;
	margin-top: 2px;
}

.secondaryText {
	margin-left: 10px;
	padding-top: 5px;
	color: white;
	font-size: .9rem;
	letter-spacing: 4px;
	font-weight: 500;
	text-align: center;
	line-height: 1rem;
	opacity: .5;
}

.navbarEnd {
	margin-right: 35px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.navbarBurger {
	height: 45px;
	width: 45px;
	background: transparent;
	border: none;
	border-radius: 50%;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.navbarBurger:focus, .navbarBurger:active {
	box-shadow: none !important;
}

.navbarBurgerOpen {
	composes: navbarBurger;
}

.navbarBurger span {
	display: block;
	height: 5px;
	margin: 4px 0;
	width: 40px;
	border-radius: 15px;
}

.navbarBurger span:nth-child(1) {
	background-color: $primary-green;
	transform-origin: right top;
	transition: transform .2s ease-out, background-color .2s ease-out;
}

.navbarBurger span:nth-child(2) {
	background-color: $secondary-green;
	transition: background-color .2s ease-out;
}

.navbarBurger span:nth-child(3) {
	background-color: $tertiary-green;
	transform-origin: right bottom;
	transition: transform .2s ease-out, background-color .2s ease-out;
}

.navbarBurger:hover span {
	opacity: .75;
}

.navbarBurgerOpen span:nth-child(1) {
	transform: translateY(4px) rotate(-45deg) scale(.70);
	background-color: white;
}

.navbarBurgerOpen span:nth-child(2) {
	background-color: transparent;
}

.navbarBurgerOpen span:nth-child(3) {
	transform: translateY(-4px) rotate(45deg) scale(.70);
	background-color: white;
}

.navbarAvatar {
	composes: navbarBurger;
	position: relative;
	opacity: 1;
	background-color: transparent;
	box-shadow: 0px 0px 3px rgba(255, 255, 255, .95) !important;
}

.navbarAvatar:hover {
	box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, .8) !important;
}

.navbarAvatarIcon {
	position: absolute;
	height: 100%;
	width: 100%;
	opacity: 1;
}

.navbarMenu {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	top: calc(100% - 1px);
	right: 0;
	width: 300px;
	background-color: $background-blue;
	border-bottom-left-radius: 10px;
	transform-origin: top center;
	transform: scaleY(.1);
	transition: all .2s ease-out;
}

.navbarMenuOpen {
	composes: navbarMenu;
	visibility: visible;
	opacity: 1;
	transform: scaleY(1);
}

.navbarMenuList {
	margin: 30px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.navbarMenuList li {
	margin: 8px 0;
	font-size: 1.1rem;
	color: white;
	cursor: pointer;
}

.navbarMenuList li a {
	color: inherit;
}

.navbarMenuList li:hover {
	opacity: .75;
}

.navbarMenuUsername {
	margin-bottom: 3px !important;
	cursor: auto !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.navbarMenuUsername:hover {
	opacity: 1 !important;
}

.navbarMenuUsername span:first-child {
	color: $quaternary-green;
	text-align: center;
	font-weight: 500;
	line-height: 1.2;
}

.navbarMenuUsername span:last-child {
	color: $quaternary-green;
	font-weight: 400;
	font-size: .8rem;
}

.navbarMenuHelp {
	height: 23px;
}

.navbarMenuHelpIcon {
	height: 100%;
	width: auto;
}

/* MOBILE */
@media (max-width: 768px) {
	.navbarMenu {
		width: 100%;
		border-bottom-left-radius: 0;
	}	
	.navbarStart {
		margin: 0 10px;
	}
	.secondaryText {
		margin-left: 10px;
		padding-top: 0;
		font-size: .7rem;
		width: 70px;
		letter-spacing: 1px;
	}
	.navbarEnd {
		margin-right: 10px;
	}
	.username {
		display: none;
	}
}