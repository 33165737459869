@import '../../assets/css/_variables.scss';

.popup {
  position: absolute;
  top: 80px;
  left: 50%;
  width: 300px;
	z-index: 10;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0px 0px 10px 0px $box-shadow-darker-color;
	padding: 10px 30px 10px 10px;
	display: flex;
	align-items: center; 
	visibility: hidden;
	opacity: 0;
	transform-origin: bottom center;
	transform: translateX(-50%) scaleY(.1);
	transition: all .2s ease-out;
}

.popupShown {
	composes: popup;
	visibility: visible;
	opacity: 1;
	transform: translateX(-50%) scaleY(1);
}

.closeButton {
	position: absolute;
	top: 4px;
	right: 4px;
	background-color: white;
	border: 1px solid $primary-green;
}

.closeButton::before, .closeButton::after {
	background-color: $primary-green;
}

.closeButton:hover {
	background-color: white;
	border-color: $quaternary-green;
}

.closeButton:hover::before, .closeButton:hover::after {
	background-color: $quaternary-green;
}

.closeButton:focus {
	background-color: white;
}

.star {
	height: 27px;
	width: 27px;
	margin: 0 15px 0 5px;
	stroke-width: 3;
	stroke: $color-yellow;
	cursor: pointer;
}

.star:hover {
	fill: $color-yellow-light-hover;
}

.starSaved {
	composes: star;
	stroke: $color-yellow;
	fill: $color-yellow;
}

.starSaved:hover {
	fill: $color-yellow-full-hover;
}

.dataContainer {
	width: 100%;
}

.header {
	font-weight: 700;
	text-align: center;
	margin-bottom: 5px;
	line-height: 1.1; 
	color: $route-light-blue;
}

.details {
	display: flex;
	justify-content: center;
	align-items: center;
}

.details span {
	font-size: .9rem;
	opacity: .6;
}

.details span:not(:last-child) {
	margin-right: 15px;
}

.socialMedia {
	margin-top: 7px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.twitterContainer {
	height: 18px;
	width: auto;
}

.facebookContainer {
	height: 18px;
	width: auto;
	margin: 0 15px;
	cursor: pointer;
}

.linkContainer {
	height: 18px;
	width: auto;
	cursor: pointer;
}

.twitterContainer:hover, .facebookContainer:hover, .linkContainer:hover {
	opacity: .8;
}

.socialMediaIcon {
	height: 100%;
	width: auto;
}

.linkIcon {
	height: 100%;
	width: auto;
	transition: fill .1s ease-out;
}

.copiedLinkIcon {
	composes: linkIcon;
	fill: $tertiary-green;
}

.hiddenUrlInput {
	position: absolute;
	top: -9999px;
  left: -9999px;
	opacity: 0;
}

@media (max-width: 500px) {
	.popup {
		left: calc(50% + 4px);
		max-width: calc(100% - 100px);
		flex-direction: column;
	}
	.star {
		order: 1;
		margin: 7px 0 0 4px;
	}
}