/* GLOBAL VARIABLES */
$primary-green: #018100;
$primary-green-light: rgba(0, 129, 0, .19);
$secondary-green: #79D60F;
$tertiary-green: #CBFF00;
$quaternary-green: #B7E77E;
$quaternary-green-light: rgba(183, 231, 126, .7);
$primary-light-green: #F8FFF4;
$primary-light-green-transp: rgba(248, 255, 244, .9);
$secondary-light-green: #F4FFEC;
$secondary-light-green-transp: rgba(244, 255, 236, .9);
$tertiary-light-green: #c6e4c3;
$primary-grey: #707070;
$button-grey: #dbdbdb;
$background-blue: #1D2B4C;
$route-light-blue: #87AADE;
$box-shadow-color: rgba(25, 5, 5, 0.3);
$box-shadow-lighter-color: rgba(0, 0, 0, 0.16);
$box-shadow-darker-color: rgba(0,0,0,.6);
$primary-text: #3A3A3A;
$green-text-shadow: 0px 3px 6px #00000029;
$color-success: #00b289;
$color-success-light: #dbfff7;
$color-error: #FF7273;
$color-error-light: #ffecec;
$color-error-dark: #BE5556;
$color-yellow: rgb(255, 204, 0);
$color-yellow-light-hover: rgba(255, 204, 0, .15);
$color-yellow-full-hover: rgba(255, 204, 0, .75);