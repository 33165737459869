@import '../../assets/css/_variables.scss';

.container {
	position: absolute;
	top: 150px;
	right: 12px;
	z-index: 5;
}

.mapStyleButton {
	position: absolute;
	top: 0;
	right: 0;
	height: 30px;
	width: 30px;
	padding: 3px;
	border: none;
	box-shadow: 0px 0px 5px 1px $box-shadow-color;
}

.mapStyleButton:hover {
	background-color: $secondary-light-green;
}

.mapStyleButton:focus {
	border: none !important;
	box-shadow: 0px 0px 5px 1px $box-shadow-color !important;
}

.layersIcon {
	height: 80%;
	width: 80%;
	fill: $primary-grey;
}

.tooltip {
	position: absolute;
	top: 35px;
	right: 0;
	white-space: nowrap;
	padding: 1px 7px;
	border-radius: 7px;
	font-size: .65rem;
	background-color: $primary-green;
	color: white;
	font-weight: 400;
	visibility: hidden;
	opacity: 0;
	transition: all .3s ease-out;
}

@media (hover: hover) {
	.mapStyleButton:hover + .tooltip {
		visibility: visible;
		opacity: 1;
		transition: all .3s ease-out .4s;
	}
}

.menu {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	top: 35px;
	right: 0;
	background-color: white;
	border-radius: 4px;
	padding: 2px;
	z-index: 10;
	box-shadow: 0px 0px 5px 1px $box-shadow-color;
	transition: all .2s ease-out;
}

.menuOpen {
	composes: menu;
	visibility: visible;
	opacity: 1;
	transform: translateY(10px);
}

.triangleIcon {
	position: absolute;
	top: -13px;
	right: 7px;
	height: 15px;
	z-index: 10;
	transform: rotate(-90deg);
	fill: white;
}

.menuButton {
	width: 100%;
	font-size: .9rem;
	border: 0;
	color: $primary-text;
}

.menuButton:hover {
	background-color: $primary-light-green;
}

.menuButton:focus {
	box-shadow: none !important;
}

.menuButtonSelected {
	composes: menuButton;
	background-color: $primary-green;
	color: white;
}

.menuButtonSelected:hover {
	background-color: $primary-green;
	color: white;
}

/* MOBILE */
@media (max-width: 500px) {
	.container {
		right: 7px;
	}
}