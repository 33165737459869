@import '../../assets/css/_variables.scss';

.popup {
  position: absolute;
  top: 80px;
  left: 50%;
  width: 350px;
  max-width: 95%;
  height: 310px;
	z-index: 10;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0px 0px 10px 0px $box-shadow-darker-color;
	padding: 20px 15px;
	overflow-y: auto;
	visibility: hidden;
	opacity: 0;
	transform-origin: bottom center;
	transform: translateX(-50%) scaleY(.1);
	transition: all .2s ease-out;
}

.popupShown {
	composes: popup;
	visibility: visible;
	opacity: 1;
	transform: translateX(-50%) scaleY(1);
}

.editButton {
	position: absolute;
	top: 4px;
	left: 4px;
	height: 24px;
	width: 24px;
	padding: 0;
	border: none;
	background-color: transparent;
}

.editButton:focus {
	box-shadow: none !important;
}

.editIcon {
	fill: $primary-green;
}

.editButton:hover .editIcon {
	fill: $quaternary-green;
}

.closeButton {
	position: absolute;
	top: 4px;
	right: 4px;
	background-color: white;
	border: 1px solid $primary-green;
}

.closeButton::before, .closeButton::after {
	background-color: $primary-green;
}

.closeButton:hover {
	background-color: white;
	border-color: $quaternary-green;
}

.closeButton:hover::before, .closeButton:hover::after {
	background-color: $quaternary-green;
}

.closeButton:focus {
	background-color: white;
}

.image {
	display: block;
	max-height: 150px;
	width: auto;
	margin: 0 auto;
}

.header {
	font-weight: 700;
	text-align: center;
	margin: 5px 0;
	color: $primary-green;
}

.description {
	background-color: $secondary-light-green;
	padding: 10px;
	font-size: .8rem;
	line-height: 1rem;
}

.socialMedia {
	margin-top: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.twitterContainer {
	height: 18px;
	width: auto;
}

.facebookContainer {
	height: 18px;
	width: auto;
	margin: 0 15px;
	cursor: pointer;
}

.linkContainer {
	height: 18px;
	width: auto;
	cursor: pointer;
}

.twitterContainer:hover, .facebookContainer:hover, .linkContainer:hover {
	opacity: .8;
}

.socialMediaIcon {
	height: 100%;
	width: auto;
}

.linkIcon {
	height: 100%;
	width: auto;
	transition: fill .1s ease-out;
}

.copiedLinkIcon {
	composes: linkIcon;
	fill: $tertiary-green;
}

.hiddenUrlInput {
	position: absolute;
	top: -9999px;
  left: -9999px;
	opacity: 0;
}

/* Style Description Scrollbar (Webkit only and only for desktop) */
@media (min-width: 1024px) {
	.popup::-webkit-scrollbar-track
	{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
		background-color: transparent;
		border-radius: 5px;
	}
	.popup::-webkit-scrollbar
	{
		width: 10px;
		border-radius: 5px;
		background-color: transparent;
	}
	.popup::-webkit-scrollbar-thumb
	{
		border-radius: 5px;
		border: 1px solid transparent;
		background-clip: padding-box;
		background-color: $tertiary-light-green;
	}
}