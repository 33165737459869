@import '../../assets/css/_variables.scss';

.notification {
  position: absolute;
  top: 65px;
  left: 50%;
  width: 450px;
  max-width: calc(100% - 60px);
  min-height: 60px;
	z-index: 50;
	background-color: $primary-light-green;
	box-shadow: 0px 0px 7px 0px $box-shadow-color;
	border-radius: 8px;
	padding: 10px 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
	cursor: pointer;
	transform-origin: top center;
	transform: scaleY(.1) translateX(-50%);
	transition: all .2s ease-out;
}

.notification.show {
	visibility: visible;
	opacity: 1;
	transform: scaleX(1) translateX(-50%);
}

.basicText {
	color: $primary-green;
	font-weight: 500;
	line-height: 1rem;
}

.successMessage {
	color: $secondary-green;
	display: flex;
	align-items: center;
}

.successIcon {
	height: 18px;
	width: 18px;
	fill: $secondary-green;
	margin-right: 7px;
}

.errorMessage {
	color: $color-error;
	display: flex;
	align-items: center;
}

.errorIcon {
	height: 18px;
	width: 18px;
	fill: $color-error;
	margin-right: 7px;
}

.successMessage p, .errorMessage p {
	width: calc(100% - 25px);
	line-height: 1rem;
} 