@import '../../assets/css/_variables.scss';

.filtersButton {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	height: 40px;
	width: 80px;
	padding: 0;
	border-radius: 8px;
	border: none;
	box-shadow: 0px 0px 5px 1px $box-shadow-darker-color;
	z-index: 5;
	visibility: hidden;
	opacity: 0;
	transform-origin: center bottom;
	transition: all .4s ease-out;
}

.filtersButton:hover {
	background-color: $secondary-light-green;
}

.filtersButton:focus {
	border: none !important;
	box-shadow: 0px 0px 5px 1px $box-shadow-color !important;
}

.filtersButtonShow {
	composes: filtersButton;
	visibility: visible;
	opacity: 1;
}

.filtersButtonIcon {
	height: 100%;
	width: 70%;
}

.tooltip {
	position: absolute;
	bottom: 1px;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
	padding: 1px 7px;
	border-radius: 7px;
	font-size: .65rem;
	background-color: $primary-green;
	color: white;
	font-weight: 400;
	z-index: 15;
	visibility: hidden;
	opacity: 0;
	transition: all .3s ease-out;
}

@media (hover: hover) {
	.filtersButton:hover + .tooltip {
		visibility: visible;
		opacity: 1;
		transition: all .3s ease-out .4s;
	}
}

.filtersContainer {
	position: absolute;
	bottom: 20px;
	left: 50%;
	width: 450px;
	padding: 30px 30px 45px 15px;
	border-radius: 12px;
	background-color: $primary-light-green-transp;
	z-index: 15;
	visibility: hidden;
	opacity: 0;
	transform-origin: center bottom;
	transform: translateX(-50%) scale(.1, .27);
	transition: all .4s ease-out;
}

.filtersContainerOpen {
	composes: filtersContainer;
	transform: translateX(-50%) scale(1,1);
	visibility: visible;
	opacity: 1;
}

.inputRangeContainer {
	position: relative;
	padding-left: 40px;
}

.inputRangeContainer:not(:last-of-type) {
	margin-bottom: 40px;
}

.minimizeButton {
	position: absolute;
	top: -17px;
	left: 50%;
	height: 15px;
	width: 120px;
	background-color: $primary-light-green-transp;
	border: none;
	border-radius: 8px;
	padding: 1px;
	transform-origin: bottom center;
	transform: translateX(-50%) scale(1);
	transition: all .1s ease-out;
}

.minimizeButton:hover {
	transform: translateX(-50%) scale(1.2);
}

.minimizeButton:focus {
	box-shadow: none !important;
}

.minimizeIcon {
	height: 100%;
	width: auto;
	fill: $primary-green;
}

.filterIcon {
	position: absolute;
	top: -15px;
	left: 0;
	fill: $primary-green;
}

.resetButton {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	height: 20px;
	background-color: transparent;
	border: 1px solid $primary-green;
	color: $primary-green;
	font-size: .8rem;
	font-weight: 700;
	border-radius: 5px;
	overflow: hidden;
}

.resetButton:focus {
	color: $primary-green;
	border-color: $primary-green;
	box-shadow: none !important;
}

.resetButton:hover {
	background-color: $primary-green;
	color: white;
	border-color: $primary-green;
	// color: $quaternary-green;
	// border-color: $quaternary-green;
}

@media (max-width: 500px) {
	.filtersButton {
		bottom: 15px;
	}
	.filtersContainer {
		bottom: 15px;
		max-width: calc(100% - 14px);
	}
}