@import '../../assets/css/_variables.scss';

.header {
	margin: 0 0 20px;
	text-align: center;
	font-size: 1.5rem;
	color: $primary-green;
	text-shadow: $green-text-shadow;
}

.errorDisplay {
	display: flex;
	align-items: center;
	margin: 0 0 15px;
}

.errorIcon {
	height: 18px;
	width: 18px;
	min-height: 18px;
	min-width: 18px;
	margin-right: 10px;
}

.errorMessage {
	color: $color-error;
	font-size: .9rem;
	line-height: 1rem;
}

.field:not(:last-child) {
	margin-bottom: 7px;
}

.input {
	height: 40px;
	width: 100%;
	font: inherit;
	background-color: white;
	border: 1px solid white;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
}

.input:hover {
	border-color: $tertiary-green;
}

.input:focus {
	outline: none;
	border-color: $secondary-green;
	box-shadow: 0 0 3px 3px $secondary-light-green;
}

.input::placeholder {
	color: $tertiary-light-green;
}

.inputIconContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.inputIcon {
	height: 40%;
	fill: $primary-green;
}

.conditions {
	margin-top: 15px;
	padding: 0 1px;
	font-size: .9rem;
}

.conditions input {
	margin-right: 5px;
	cursor: pointer;
}

.conditions label {
	display: flex;
	align-items: baseline;
	border: 1px solid transparent;
	border-radius: 4px;
	padding: 1px 0;
	cursor: pointer;
}

.conditions label a {
	color: $primary-green;
	text-decoration: none;
}

.conditions label a:hover {
	text-decoration: underline;
}

.primaryButton {
	display: block;
	width: 100%;
	height: 40px;
	margin: 20px auto 0;
	background-color: $quaternary-green;
	color: $primary-green;
	font-size: 1rem;
	font-weight: 700;
	border: 0;
	border-radius: 5px;
}

.primaryButton:focus {
	color: $primary-green;
	box-shadow: none !important;
}

.primaryButton:hover {
	background-color: $quaternary-green-light;
	color: $primary-green;
	border: 1px solid $quaternary-green-light;
}

.primaryButton:global(.is-loading)::after {
	border-left-color: white;
	border-bottom-color: white;
}

.linksContainer {
	margin-top: 30px;
}

.link {
	margin-top: 10px;
	text-align: center;
	font-size: .95rem;
	color: $secondary-green;
	opacity: .7;
	cursor: pointer;
	outline: none;
}

.link:hover, .link:focus {
	text-decoration: underline;
	opacity: 1;
}