@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700');
@import './assets/css/_variables.scss';

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Rubik', Helvetica Neue, Helvetica, sans-serif;
  color: $primary-text;
  overflow-y: hidden !important;
}

* {
  box-sizing: border-box; 
}


/* BULMA IMPORTS */
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/base/_all.sass";
@import "bulma/sass/elements/button.sass";
@import "bulma/sass/form/_all.sass";
@import "bulma/sass/elements/other.sass";
@import "bulma/sass/components/modal.sass";


/* BULMA ADJUSTMENTS */
.navbar-brand a.navbar-item:focus, .navbar-brand a.navbar-item:hover, .navbar-burger:hover {
  background-color: white !important;
}

.control.has-icons-left .icon {
  height: 100%;
}

/* MAPBOX ADJUSTMENTS */
.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-control-container {
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.mapboxgl-ctrl-top-right {
  top: 80px;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
  margin: 0 12px 0 0;
  box-shadow: 0px 0px 5px 1px $box-shadow-color;
}

.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='-18 -18 60 60' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath fill='%23707070' d='M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z'/%3E %3C/svg%3E");
}

.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='-25 -25 74 74' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath fill='%23707070' d='M0 10h24v4h-24z'/%3E %3C/svg%3E");
}

.mapboxgl-ctrl > button:hover {
  background-color: $secondary-light-green;
}

.mapboxgl-ctrl-group > button:focus {
  box-shadow: none;
}

/* REACT INPUT RANGE */
.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}

.input-range__slider {
  appearance: none;
  background: $tertiary-green;
  border: 2px solid $primary-green;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem;
  touch-action: none;
}
  
.input-range__slider:active {
  transform: scale(1.3);
}
  
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
  
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: $primary-green;
  font-size: 0.8rem;
  font-weight: 500;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min, .input-range__label--max {
  display: none;
  bottom: -1.4rem;
  position: absolute;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__slider-container:first-of-type .input-range__label--value {
  top: auto;
  bottom: -1.4rem;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}

.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: $primary-green;
}

/* INTRO.JS */
.introjs-helperNumberLayer {
  background: $secondary-green !important;
}

/* COOKIES CONSENT */
.cookieConsent {
  align-items: center !important;
  opacity: .9 !important;
}

.cookieConsent div {
  margin: 10px 15px 10px 60px !important;
  font-size: .95rem;
}

.cookieConsent button {
  margin: 10px 60px 10px 15px !important;
  font-family: 'Rubik', Helvetica Neue, Helvetica, sans-serif;
  font-size: .9rem !important;
  font-weight: 500;
  border-radius: 4px !important;
  background-color: $primary-green !important;
  color: white !important;
}

.cookieConsent button:hover {
  // box-shadow: $primary-box-shadow;
  opacity: 0.95;
}

.cookieConsent .cookies-link {
  margin-left: 10px;
  color: white;
  opacity: .6;
}

.cookieConsent .cookies-link:hover {
  opacity: .7;
}


/* MOBILE */
@media (max-width: 640px) {
  /* MAPBOX CSS ADJUSTMENTS */
  .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
    margin: 0 0 3px 7px;
  }
  .mapboxgl-ctrl-bottom-right {
    right: auto;
    left: 100px;
  }
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
    margin: 0 7px 3px 0;
  }
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
    height: 20px;
    width: 20px;
  }
  .mapboxgl-ctrl-logo {
    width: 70px !important;
  }
  /* COOKIES CONSENT */
  .cookieConsent {
    flex-direction: column !important;
  }  
  .cookieConsent div {
    flex: 0 1 auto !important;
    margin: 10px 10px 0px 10px !important;
    text-align: center;
  }
  .cookieConsent button {
    margin: 5px 10px 10px 10px !important;
  }
}

@media (max-width: 500px) {
  /* MAPBOX CSS ADJUSTMENTS */
  .mapboxgl-ctrl-top-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
    margin: 0 7px 0 0;
  }
}