@import '../../assets/css/_variables.scss';

.headerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 25px;
}

.header {
	font-size: 1.5rem;
	color: $primary-green;
	text-shadow: $green-text-shadow;
}

.headerIconContainer {
	height: 27px;
	width: 27px;
	border-radius: 50%;
	box-shadow: 0px 3px 6px $box-shadow-color;
	margin-right: 10px;
	margin-bottom: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.routeIcon {
	height: 70%;
	width: auto;
	margin-right: 2px;
}

.sortingContainer {
	padding: 5px 20px 10px;
	background-color: $primary-green-light;
	border-radius: 8px;
	box-shadow: 3px 3px 6px $box-shadow-lighter-color;
	margin-bottom: 10px;
}

.sortingText {
	display: block;
	color: $primary-green;
	text-align: center;
	margin-bottom: 5px;
}

.sortingButtonsContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.sortingButton {
	height: 30px;
	width: 70px;
	padding: 0;
	border: none;
	border-radius: 5px;
	box-shadow: 0px 3px 6px $box-shadow-lighter-color;
	font-size: 1rem;
	color: $primary-green;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: transform .2s ease, box-shadow .2s ease;
}

.sortingButton:not(:last-child) {
	margin-right: 10px;
}

.sortingButton:focus {
	border: none;
	box-shadow: 0px 3px 6px $box-shadow-lighter-color !important;
	color: $primary-green;
}

.sortingButton:hover {
	color: $primary-green;
	box-shadow: 0px 4px 6px $box-shadow-color;
	transform: translateY(-2px);
}

.sortingButtonSelected {
	composes: sortingButton;
	background-color: $primary-green;
	color: white;
}

.sortingButtonSelected:focus {
	color: white;
}

.sortingButtonSelected:hover {
	color: white;
}

.distanceIcon {
	height: 60%;
	fill: $primary-green;
	margin-right: 2px;
}

.timeIcon {
	height: 50%;
	fill: $primary-green;
	margin-right: 4px;
}

.slopeIcon {
	height: 50%;
	fill: $primary-green;
	margin-right: 5px;
}

.sortingButtonSelected .distanceIcon, .sortingButtonSelected .timeIcon, .sortingButtonSelected .slopeIcon {
	fill: white;
}

.sortingButton span {
	font-size: .8rem;
}

.routesContainer {
	min-height: 30px;
	padding: 10px;
	background-color: $primary-green-light;
	border-radius: 8px;
	box-shadow: 3px 3px 6px $box-shadow-lighter-color;
	overflow-y: auto;
}

.route {
	background-color: white;
	border-radius: 5px;
	padding: 5px 10px;
	cursor: pointer;
	transition: transform .2s ease, box-shadow .2s ease;
}

.route:not(:last-child) {
	margin-bottom: 5px;
}

.route:hover {
	transform: translateX(2px);;
	box-shadow: 0px 0px 6px rgba(1, 129, 0, .75);
}

.routeName {
	display: block;
	font-size: .9rem;
	font-weight: 700;
	text-align: center;
	line-height: 1.1; 
	color: $route-light-blue;
}

.routeDetails {
	display: flex;
	justify-content: center;
	align-items: center;
}

.routeDetails span {
	font-size: .8rem;
	opacity: .6;
}

.routeDetails span:not(:last-child) {
	margin-right: 15px;
}