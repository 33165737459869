@import '../../assets/css/_variables.scss';

.headerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 25px;
}

.header {
	font-size: 1.5rem;
	color: $primary-green;
	text-shadow: $green-text-shadow;
}

.userContainer {
	margin-bottom: 30px;
	padding: 15px 15px 20px;
	border-radius: 8px;
	box-shadow: 0px 3px 6px $box-shadow-lighter-color;
}

.userName {
	text-align: center;
	color: $quaternary-green;
	font-size: 1.1rem;
	font-weight: 500;
}

.userRank {
	margin-top: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.userRank .rank {
	margin-left: 5px;
	color: $primary-grey;
}

.userRank .rankIcon {
	margin-left: 5px;
	height: 25px;
}

.userPins {
	margin-top: 5px;
	text-align: center;
	color: $primary-grey;
	font-size: .85rem;
}

.ranksMessage {
	width: 50%;
	margin: 15px auto 0;
	padding: 5px;
	border-radius: 8px;
	background-color: $quaternary-green;
	color: $primary-green;
	box-shadow: 0px 3px 6px $box-shadow-lighter-color;
	text-align: center;
	font-size: .8rem;
	line-height: 1.2;
}

.ranksMessage p:first-child {
	margin-bottom: 3px;
}

.subheaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}

.subheaderIconContainer {
	height: 27px;
	width: 27px;
	border-radius: 50%;
	box-shadow: 0px 3px 6px $box-shadow-color;
	margin-right: 10px;
	margin-bottom: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.starIcon {
	height: 60%;
	width: auto;
	fill: $color-yellow;
	margin: 0 0 .5px 1.5px;
}

.pinIcon {
	height: 70%;
	width: auto;
	margin-left: 4px;
}

.subheader {
	font-size: 1.1rem;
	text-shadow: $green-text-shadow;
}

.routesPinsContainer {
	min-height: 30px;
	margin-bottom: 30px;
	padding: 10px;
	background-color: $primary-green-light;
	border-radius: 8px;
	box-shadow: 3px 3px 6px $box-shadow-lighter-color;
	overflow-y: auto;
}

.route {
	background-color: white;
	border-radius: 5px;
	padding: 5px 15px;
	cursor: pointer;
	transition: transform .2s ease, box-shadow .2s ease;
}

.route:not(:last-child) {
	margin-bottom: 5px;
}

.route:hover {
	transform: translateX(2px);;
	box-shadow: 0px 0px 6px rgba(1, 129, 0, .75);
}

.routeName {
	display: block;
	font-size: .9rem;
	font-weight: 700;
	text-align: center;
	line-height: 1.1; 
	color: $route-light-blue;
}

.routeDetails {
	display: flex;
	justify-content: center;
	align-items: center;
}

.routeDetails span {
	font-size: .8rem;
	opacity: .6;
}

.routeDetails span:not(:last-child) {
	margin-right: 15px;
}

.pin {
	composes: route;
	display: flex;
	align-items: center;
}

.pinCategoryIcon {
	height: 30px;
	width: auto;
	margin-right: 10px;
}

.pinName {
	display: block;
	// min-width: 50%;
	font-size: .9rem;
	font-weight: 700;
	line-height: 1.1; 
	color: $primary-green;
}

.deleteButton {
	display: block;
	height: 40px;
	margin: 40px auto 0;
	background-color: white;
	color: $color-error;
	font-size: 1rem;
	font-weight: 700;
	border: 1px solid $color-error;
	border-radius: 5px;
}

.deleteButton:focus {
	color: $color-error;
	border-color: $color-error;
	box-shadow: none !important;
}

.deleteButton:hover {
	background-color: $color-error-light;
	color: $color-error;
	border-color: $color-error;
}

.deleteButton:global(.is-loading)::after {
	border-left-color: white;
	border-bottom-color: white;
}