@import '../../assets/css/_variables.scss';

.messageContainer {
	width: 350px;
	background-color: white;
	z-index: 50;
	border-radius: 8px;
	padding: 20px;
}

.header {
	margin-bottom: 10px;
	font-weight: 700;
	text-align: center;
}

.text {
	margin-bottom: 15px;
	font-size: .9rem;
	line-height: 1.2;
	text-align: center;
}

.buttonsContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.buttonsContainer button:focus {
	box-shadow: none !important;
}

.buttonsContainer button {
	width: 45%;
}